import { create } from "zustand";

import { ExpectedShipmentResponseDto } from "@/types/api/expected";
import { InventoryItemResponseDto } from "@/types/api/items";
import {
  ShipmentResponseDto,
  ShopsResponseDto,
  StorageParamsDto,
  StorageResponseDto,
} from "@/types/api/shipments";

import { requestNames } from "./useServicesRequestStore";

export const defaultStorageParams = {
  page: 1,
  filter: {
    "q[date_received_at_gteq]": null,
    "q[date_received_at_lteq]": null,
    "q[tracking_number_contains]": null,
    "q[vendor_eq]": null,
    "q[location_in]": null,
    "q[weight_gteq]": null,
    "q[weight_lteq]": null,
  },
};

export interface StorageItem
  extends Omit<StorageResponseDto, "service_requests_count"> {
  serviceRequestCount: { [key: string]: string };
  consolidation_id?: number;
  prohibited?: boolean;
  customer_comment?: string;
  carrier?: string;
  week_number?: number;
  shipped_on?: string;
}

export interface DetailedShipments {
  [key: string]: DetailedShipment;
}

export type DetailedShipment = ShipmentResponseDto & {
  customer_comment?: string;
  consolidation?: any;
};

interface UseStorageStore {
  isLoadingStorage: boolean;
  isLoadingInventoryItems: boolean;
  isHasStorageItems: boolean;
  isAddParcelsToCart: boolean;
  isLoadingCustomsDeclarations: boolean;
  isLoadingShipment: boolean;
  storageParams: StorageParamsDto;
  storageItems: StorageItem[];
  selectedStorage: any[];
  totalStorageWeight: number;
  totalStorageAmount: number;
  totalExpectedAmount: number;
  totalStoragePage: number;
  inventoryItemsSearch: InventoryItemResponseDto[];
  expectedItemsSearch: ExpectedShipmentResponseDto[];
  selectedExpectedItemId: number | null;
  selectedStorageItemId: number | null;
  shops: ShopsResponseDto[];
  expectedShops: ShopsResponseDto[];
  detailedShipment: DetailedShipments;

  updateIsLoadingStorage: (isLoading: boolean) => void;
  updateIsLoadingInventoryItems: (isLoading: boolean) => void;
  updateIsLoadingShipment: (isLoading: boolean) => void;
  updateIsHasStorageItems: (isHasItems: boolean) => void;
  updateIsAddParcelsToCart: (isAdd: boolean) => void;
  updateStorageParams: (params: StorageParamsDto) => void;
  updateSelectedStorage: (selected: any[]) => void;
  updateStorageItems: (items: StorageItem[]) => void;
  updateStorageTotalWeight: (weight: number) => void;
  updateStorageTotalAmount: (amount: number) => void;
  updateExpectedTotalAmount: (amount: number) => void;
  updateStorageTotalPage: (count: number) => void;
  updateInventoryItemsSearch: (items: InventoryItemResponseDto[]) => void;
  updateExpectedItemsSearch: (items: ExpectedShipmentResponseDto[]) => void;
  updateSelectedExpectedItemId: (id: number | null) => void;
  updateSelectedStorageItemId: (id: number | null) => void;
  updateIsLoadingCustomsDeclarations: (isLoading: boolean) => void;
  updateShops: (shops: ShopsResponseDto[]) => void;
  updateExpectedShops: (expectedShops: ShopsResponseDto[]) => void;
  updateDetailedShipment: (shipments: DetailedShipments) => void;
}

const initialState = {
  isLoadingStorage: false,
  isLoadingInventoryItems: false,
  isHasStorageItems: false,
  isAddParcelsToCart: false,
  isLoadingShipment: false,
  isLoadingCustomsDeclarations: false,
  totalStorageWeight: 0,
  totalStorageAmount: 0,
  totalExpectedAmount: 0,
  totalStoragePage: 0,
  storageParams: defaultStorageParams,
  storageItems: [],
  selectedStorage: [],
  inventoryItemsSearch: [],
  expectedItemsSearch: [],
  shops: [],
  expectedShops: [],
  selectedExpectedItemId: null,
  selectedStorageItemId: null,
  detailedShipment: {},
};

const useStorageStore = create<UseStorageStore>((set, get) => ({
  ...initialState,
  updateIsLoadingStorage: (isLoading) =>
    set(() => ({
      isLoadingStorage: isLoading,
    })),
  updateIsLoadingInventoryItems: (isLoading) =>
    set(() => ({
      isLoadingInventoryItems: isLoading,
    })),
  updateIsHasStorageItems: (isHasItems) =>
    set(() => ({
      isHasStorageItems: isHasItems,
    })),
  updateIsAddParcelsToCart: (isAdd) =>
    set(() => ({
      isAddParcelsToCart: isAdd,
    })),
  updateStorageParams: (params) =>
    set(() => ({
      storageParams: params,
    })),
  updateStorageTotalWeight: (weight) =>
    set(() => ({
      totalStorageWeight: weight,
    })),
  updateStorageTotalAmount: (amount) =>
    set(() => ({
      totalStorageAmount: amount,
    })),
  updateStorageTotalPage: (count) =>
    set(() => ({
      totalStoragePage: count,
    })),
  updateExpectedTotalAmount: (amount) =>
    set(() => ({
      totalExpectedAmount: amount,
    })),
  updateStorageItems: (items) =>
    set(() => ({
      storageItems: items,
    })),
  updateSelectedStorage: (selected) =>
    set(() => ({
      selectedStorage: selected,
    })),
  updateInventoryItemsSearch: (items) =>
    set(() => ({
      inventoryItemsSearch: items,
    })),
  updateExpectedItemsSearch: (items) =>
    set(() => ({
      expectedItemsSearch: items,
    })),
  updateSelectedExpectedItemId: (id) =>
    set(() => ({
      selectedExpectedItemId: id,
    })),
  updateIsLoadingCustomsDeclarations: (isLoading) =>
    set(() => ({
      isLoadingCustomsDeclarations: isLoading,
    })),
  updateSelectedStorageItemId: (id) =>
    set(() => ({
      selectedStorageItemId: id,
    })),
  updateShops: (shops) =>
    set(() => ({
      shops,
    })),
  updateExpectedShops: (expectedShops) =>
    set(() => ({
      expectedShops,
    })),
  updateIsLoadingShipment: (isLoading) =>
    set(() => ({
      isLoadingShipment: isLoading,
    })),
  updateDetailedShipment: (detailedShipment) =>
    set(() => ({
      detailedShipment,
    })),
}));

export const removeConsolidationFromShipment = (id: number) => {
  const {
    updateDetailedShipment,
    updateStorageItems,
    storageItems,
    detailedShipment,
  } = useStorageStore.getState();

  const { consolidation, ...detailedShipmentWithId } = detailedShipment[id];
  updateDetailedShipment({
    ...detailedShipment,
    [id]: { ...detailedShipmentWithId },
  });
  const itemIndex = storageItems.findIndex((item) => item.id === id);
  const storageItem = storageItems[itemIndex];

  if (!storageItem) return;

  const { consolidation_id, ...storageItemWithId } = storageItems[itemIndex];
  updateStorageItems(
    storageItems.map((item, index) =>
      index === itemIndex ? { ...storageItemWithId } : item,
    ),
  );
};

export const selectParcel = (payload: string | number | number[]) => {
  const selectedStorage = useStorageStore.getState().selectedStorage;
  const updateSelectedStorage =
    useStorageStore.getState().updateSelectedStorage;

  if (Array.isArray(payload)) {
    updateSelectedStorage(payload);
    return;
  }
  const index = selectedStorage.indexOf(payload);
  updateSelectedStorage(
    index === -1
      ? [...selectedStorage, payload]
      : selectedStorage.filter((_, i) => index !== i),
  );
};

export default useStorageStore;

interface AddServiceRequestPayload {
  packageId: number;
  data: any;
}

export const addServiceRequest = (payload: AddServiceRequestPayload) => {
  const { packageId, data } = payload;
  const {
    storageItems,
    updateStorageItems,
    updateDetailedShipment,
    detailedShipment,
  } = useStorageStore.getState();
  const type = data.service_request_type.type.toLowerCase();
  const id = data.id;
  const isRequestCanceled = data.state === "canceled";
  const items = storageItems.map((item) => {
    if (item.id !== packageId) return item;

    const requestName = requestNames[type as keyof typeof requestNames];

    if (isRequestCanceled) {
      const { requestName, ...serviceRequestCountData } =
        item.serviceRequestCount;

      return { ...item, serviceRequestCount: serviceRequestCountData };
    }

    return {
      ...item,
      serviceRequestCount: {
        ...item.serviceRequestCount,
        [requestName]: "unprocessed",
      },
    };
  });

  updateStorageItems(items);
  updateDetailedShipment({
    ...detailedShipment,
    [packageId]: {
      ...detailedShipment[packageId],
      service_requests: detailedShipment[packageId].service_requests.reduce(
        (acc, request) => {
          if (request.id === id) {
            if (isRequestCanceled) {
              return acc;
            } else {
              acc.push(data);
              return acc;
            }
          } else {
            acc.push(request);
            return acc;
          }
        },
        [],
      ),
    },
  });
};

export const getSelectedExpectedItemCustomsDeclarations = () => {
  const { expectedItemsSearch: expectedItems, selectedExpectedItemId } =
    useStorageStore.getState();
  const selectedItem = expectedItems.find(
    (item) => item.id === selectedExpectedItemId,
  );

  return selectedItem && selectedItem.customs_declarations
    ? selectedItem.customs_declarations
    : [];
};

export const getStorageSelectSelectedWeight = () => {
  const { selectedStorage, storageItems } = useStorageStore.getState();

  return selectedStorage
    .reduce((weight, id) => {
      const item = storageItems.find((item) => item.id === id);
      if (!item || !item.weight) return weight;

      return weight + +item.weight;
    }, 0)
    .toFixed(2);
};
