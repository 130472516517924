import { BookTransactionHistoryItem } from "types/api/books";
import { RechargeCreditCardDto } from "types/api/recharge";

import axiosInstance from "./axios_instance";

const BooksAPI = {
  getBooksTransactionsHistory: async ({
    page,
  }: {
    page: number;
  }): Promise<BookTransactionHistoryItem[]> => {
    const res = await axiosInstance.get("/account_transactions", {
      params: { page },
    });
    return res.data;
  },
  getBooksRecipients: async ({
    searchInAddress,
  }: {
    searchInAddress?: string;
  }) => {
    const res = await axiosInstance.get("/destination_addresses", {
      params: { searchInAddress },
    });
    return res.data;
  },
  createRechargeBalance: (data: RechargeCreditCardDto) =>
    axiosInstance.post(`/recharge/credit_cards/complete`, data),
};

export default BooksAPI;
