import { create } from "zustand";

interface UseBooksStore {
  amountFunds: string;
  updateAmountFunds: (amount: string) => void;
}

const initialState = {
  amountFunds: "",
};

const useBooksStore = create<UseBooksStore>((set) => ({
  ...initialState,
  updateAmountFunds: (amount) =>
    set(() => ({
      amountFunds: amount,
    })),
}));

export default useBooksStore;
