import React, { FC } from "react";

import { Waypoint } from "react-waypoint";

import {
  useShipment,
  useStorageUpdateComment,
} from "@/hooks/react-query/storage";

import { RightPanelType } from "@/store/useRightPanelStore";
import { AddonsMethod } from "@/store/useServicesStore";
import { StorageItem } from "@/store/useStorageStore";
import { useRightPanelStore, useStorageStore } from "@/store";

import StorageItemRow from "./StorageItemRow";
import { Spinner, Wrapper } from "./StorageList.styles";

interface StorageListProps {
  disabled: boolean;
  units: string;
  parcels: StorageItem[];
  addons: AddonsMethod[];
  totalAmount: number;
  selectedParcels: number[];
  onPagination: () => void;
  handleSelect: (selectParcel: string | number | number[]) => void;
  selectedRangeParcels: (endParcelId: number) => number[];
}

const StorageList: FC<StorageListProps> = React.memo(
  ({
    addons,
    units,
    parcels,
    totalAmount,
    selectedParcels,
    handleSelect,
    selectedRangeParcels,
    onPagination,
    disabled,
  }) => {
    const { openRightPanel } = useRightPanelStore();
    const {
      updateSelectedStorageItemId,
      selectedStorageItemId,
      isAddParcelsToCart,
    } = useStorageStore();
    const { mutateAsync: updateComment } = useStorageUpdateComment();
    const hasMore = totalAmount > parcels.length;

    const handleGetShipment = (id: number) => {
      useShipment(id);
    };

    const handleFocusStorageItemInPanel = (parcel: StorageItem) => {
      updateSelectedStorageItemId(+parcel.id);
      openRightPanel(RightPanelType.STORAGE_ITEM, {
        getShipment: handleGetShipment,
        updateComment,
        hasInventoryItems: (parcel.items_quantity ?? 0) > 1,
        originalItem: parcel,
        isCartVariant: true,
        ...parcel,
      });
    };

    return (
      <Wrapper>
        {parcels.map((parcel) => (
          <StorageItemRow
            parcel={parcel}
            selectedParcel={selectedStorageItemId}
            onFocusStorageItemInPanel={handleFocusStorageItemInPanel}
            key={parcel.id}
            isChecked={selectedParcels.includes(parcel.id)}
            disabled={disabled}
            units={units}
            addons={addons}
            handleSelect={handleSelect}
            selectedRangeParcels={selectedRangeParcels}
          />
        ))}
        {hasMore && (
          <>
            <Waypoint onEnter={onPagination} />
            <Spinner key={0} isActive />
          </>
        )}
      </Wrapper>
    );
  },
);

export default StorageList;
