import React, { FC, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { AddButton } from "../Addresses.style";

import {
  ChangingAddressPanelData,
  RightPanelType,
} from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";
import { ButtonColor } from "@/enums";

interface AddAddressButtonProps {
  initialPanelData?: ChangingAddressPanelData;
  returnPreviousPanel?: RightPanelType;
  className?: string;
}

const AddAddressButton: FC<AddAddressButtonProps> = React.memo(
  ({ initialPanelData, returnPreviousPanel, className = "" }) => {
    const { t } = useTranslation("common");
    const { openRightPanel } = useRightPanelStore();

    const handleClick = useCallback(
      () =>
        openRightPanel(RightPanelType.ADDRESS_FORM, {
          isEditForm: false,
          initialPanelData,
          returnPreviousPanel,
        }),
      [openRightPanel],
    );

    return (
      <AddButton
        color={ButtonColor.Primary}
        onClick={handleClick}
        className={className}
      >
        + {t("common.addAddress", { word: t("common.another") })}
      </AddButton>
    );
  },
);

export default AddAddressButton;
