import React, { FC, useCallback, useEffect, useState } from "react";

import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CustomsDeclarationV2 from "@/components/CustomDeclaration/CustomsDeclarationV2";

import { USD } from "@/styles/form";

import { calculateCustomsDataTotal } from "@/utils/customsDataHelpers";
import formatter from "@/utils/formatter";
import {
  useCartCreateCustomsDeclaration,
  useCartDeleteCustomsDeclaration,
  useCartImportCustomInformation,
  useCartUpdateCustomsDeclaration,
} from "@/hooks/react-query/cart";
import { useAllDeliveryMethods } from "@/hooks/react-query/services";
import { ProgressDeliverySetAccessFunc } from "@/hooks";

import { useCartStore, useServicesStore } from "@/store";

import { RightSideContent } from "../";
import {
  BlockContent,
  Container,
  Content,
  DeliveryBlock,
  Heading,
  RowWrapper,
} from "../../styles";
import { Header, Spinner, Wrapper } from "./CheckCustomsData.styles";

interface CheckCustomsDataProps {
  setAccess: ProgressDeliverySetAccessFunc;
}

const CheckCustomsData: FC<CheckCustomsDataProps> = React.memo(
  ({ setAccess }) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const { allDeliveryMethods } = useServicesStore();
    const { isLoading: isLoadingAllDeliveryMethods } = useAllDeliveryMethods({
      enabled: !allDeliveryMethods.length,
    });

    const { isCartLoading, cart } = useCartStore();
    const { mutateAsync: importCustomInformation, isPending } =
      useCartImportCustomInformation();
    const { mutateAsync: createCustomsDeclaration } =
      useCartCreateCustomsDeclaration();
    const { mutateAsync: updateCustomsDeclaration } =
      useCartUpdateCustomsDeclaration();
    const { mutateAsync: deleteCustomsDeclaration } =
      useCartDeleteCustomsDeclaration();

    const customInformation = cart?.customs_declarations ?? [];

    const getTotal = (nameProp: string) =>
      customInformation.reduce(
        (sum: number, information) =>
          sum + +information[nameProp as keyof typeof information],
        0,
      );
    const [headerData, setHeaderData] = useState({
      size: customInformation.length || 0,
      total: formatter.currency(getTotal("value")),
    });

    const deliveryMethod = allDeliveryMethods.find(
      (method) => method.name === cart?.preferred_carrier,
    );

    const isShowHSCodeColumn =
      deliveryMethod && deliveryMethod.requires_hs_code;

    useEffect(() => {
      if (!customInformation.length) importCustomInformation;
    }, [customInformation]);

    const handleSubmit = useCallback(() => {
      setAccess("summary");
      navigate("/shipping/summary");
    }, [navigate, setAccess]);

    const handleCustomsDataChange = useCallback((newValues: object[]) => {
      const [newTotal, newLength] = calculateCustomsDataTotal(newValues);
      setHeaderData({
        size: newLength,
        total: newTotal,
      });
    }, []);

    return (
      <Container>
        <RowWrapper justify="center">
          <Col lg={4} md={6} sm={12} offset={{ md: 2 }}>
            <Content>
              <DeliveryBlock>
                <Wrapper>
                  <Header>
                    <Heading>
                      {`${t("shipping.checkCustomData")}: ${headerData.size} ${t(
                        "shipping.totalRows",
                      )} - $${headerData.total}`}
                      <USD>USD</USD>
                    </Heading>
                  </Header>
                  <BlockContent>
                    <Spinner
                      isActive={
                        isCartLoading &&
                        isPending &&
                        isLoadingAllDeliveryMethods
                      }
                    />
                    <CustomsDeclarationV2
                      declarations={cart?.customs_informations ?? []}
                      create={createCustomsDeclaration}
                      update={updateCustomsDeclaration}
                      remove={deleteCustomsDeclaration}
                      onSubmit={handleSubmit}
                      onCustomsDataChange={handleCustomsDataChange}
                      isWizardStep
                      isShowHSCodeColumn={isShowHSCodeColumn}
                      isHSCodeValidationRequired
                    />
                  </BlockContent>
                </Wrapper>
              </DeliveryBlock>
            </Content>
          </Col>
          <RightSideContent />
        </RowWrapper>
      </Container>
    );
  },
);

export default CheckCustomsData;
