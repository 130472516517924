import { AxiosError } from "axios";
import { toast } from "react-toastify";

export const getResponseError = (error: unknown): string | null => {
  const commonErrorMessage = "Something went wrong";

  if (!error) return null;

  if (error instanceof AxiosError && error.response?.data) {
    const { errors, error: responseError } = error.response.data;

    if (Array.isArray(errors)) {
      return errors.join(", ");
    }

    return responseError ?? error.message ?? commonErrorMessage;
  }

  return typeof error === "string" ? error : commonErrorMessage;
};

export const getResponseSuccess = (successMessage: string) => {
  return successMessage ? successMessage : null;
};

export const toastResponseError = (response: unknown, toastId?: string) => {
  const message = getResponseError(response);
  toast.error(message, { toastId: toastId, icon: false });
};

export const toastResponseSuccess = (response: string) => {
  const message = getResponseSuccess(response);
  toast.success(message, { icon: false });
};
