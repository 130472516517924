import React, { FC, Suspense } from "react";

import { Navigate, Outlet } from "react-router-dom";

import Layout from "@/layouts/Layout";
import { LoadingPage } from "@/components/LoadingPage";

import { getAuthData } from "@/utils/authData";

interface AuthorizedRouteProps {
  withHeader?: boolean;
  withMenu?: boolean;
  condition?: boolean;
  redirect?: string;
}

const AuthorizedRoute: FC<AuthorizedRouteProps> = React.memo(
  ({ withHeader = true, withMenu = true, condition = false }) => {
    const token = getAuthData()["access-token"];

    if (condition) return <Navigate to="/" />;

    return token ? (
      <Layout withHeader={withHeader} withMenu={withMenu} rightPanel>
        <Suspense fallback={<LoadingPage />}>
          <Outlet />
        </Suspense>
      </Layout>
    ) : (
      <Navigate to="/signin" />
    );
  },
);

export default AuthorizedRoute;
