import React, { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import { ToastContainer } from "@/components/ToastContainer";

import { globalStyles, theme } from "@/styles";

import { clearAuthData, getAuthData } from "@/utils/authData";
import { useGetProfile } from "@/hooks/react-query/userAuth";

import { useAuthStore, useRightPanelStore } from "@/store";
import { getSupportedLocale } from "@/translations/i18nConfig";

import AppRoutes from "./Routes";

const GlobalStyle = createGlobalStyle`${globalStyles}`;

useAuthStore.getState().fetchAuthState();

export const App: FC = React.memo(() => {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState<string | null>(null);
  const navigate = useNavigate();
  const {
    data: profileData,
    refetch: getProfile,
    isSuccess: isSuccessGetProfile,
    isError: isErrorGetProfile,
    isLoading: isLoadingGetProfile,
  } = useGetProfile({
    enabled: false,
  });

  const {
    updateIsAuthenticated,
    updateUserAuth,
    updateIsLoadingAuth,
    userAuth: { locale: currentLocale },
  } = useAuthStore();

  useEffect(() => {
    const authData = getAuthData();
    const token = authData?.["access-token" as keyof typeof authData] ?? null;

    if (!token) {
      updateIsAuthenticated(false);
      return;
    }

    getProfile();
  }, [getProfile]);

  useEffect(() => {
    if (isSuccessGetProfile && profileData) {
      updateUserAuth(profileData, true);
    }
  }, [isSuccessGetProfile, profileData]);

  useEffect(() => {
    if (isErrorGetProfile) {
      clearAuthData();
      updateIsLoadingAuth(false);
      navigate("/signin");
    }
  }, [isErrorGetProfile]);

  useEffect(() => {
    updateIsLoadingAuth(isLoadingGetProfile);
  }, [isLoadingGetProfile]);

  useEffect(() => {
    const appLocale = getSupportedLocale(currentLocale);

    if (appLocale !== locale) {
      i18n.changeLanguage(appLocale);
      setLocale(appLocale);
    }
  }, [currentLocale, setLocale, i18n, locale]);

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <>
          <AppRoutes />
          <ToastContainer />
        </>
      </ThemeProvider>
    </>
  );
});

export default App;
