import React, {
  FC,
  memo,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { useSwipeable } from "react-swipeable";
import { CSSProp } from "styled-components";

import { useRightPanelStore } from "@/store";

import {
  SwipeContainer,
  TabsTitleWrapper,
} from "./RightPanelComponents.styles";
import TabTitle from "./TabTitle";

interface TabsViewProps {
  children: ReactNode[];
  openTab?: string;
  onTabChange?: (title: string) => void;
  tabsViewStyles?: CSSProp;
}

const isReactElement = (node: ReactNode): node is ReactElement => {
  return React.isValidElement(node);
};

const TabsView: FC<TabsViewProps> = ({
  children: childrenProp,
  openTab = "",
  onTabChange,
  tabsViewStyles,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const children = childrenProp.filter(isReactElement);
  const { updateActiveTab, closeRightPanel, isOpenRightPanel } =
    useRightPanelStore();

  const handleSetActiveTab = (newIndex: number) => {
    if (onTabChange) {
      const {
        props: { title },
      } = children[newIndex];
      onTabChange(title);
    }
    setActiveTab(newIndex);
  };

  useEffect(() => {
    updateActiveTab(activeTab);

    return () => {
      updateActiveTab(0);
    };
  }, [activeTab]);

  useEffect(() => {
    const activeTab = children.findIndex(
      ({ props: { title } }) => title === openTab,
    );

    activeTab !== -1 && handleSetActiveTab(activeTab);
  }, [openTab]);

  const isSwipeDisabled = children.some(
    (child) => child && child.props && child.props.disabled,
  );

  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventDate) => {
      if (!isOpenRightPanel || activeTab !== 0) return;
      history.pushState(null, "", location.href);
      closeRightPanel();
      eventDate.event.stopPropagation();
      eventDate.event.preventDefault();
      history.go(1);
    },
    touchEventOptions: { passive: !isOpenRightPanel },
  });

  return (
    <>
      <TabsTitleWrapper index={activeTab} tabsViewStyles={tabsViewStyles}>
        {children.map(({ props: { title, disabled } }, index) => (
          <TabTitle
            key={index}
            isActive={activeTab === index}
            handleClick={() => {
              if (!disabled) {
                setActiveTab(index);
                handleSetActiveTab(index);
              }
            }}
          >
            {title}
          </TabTitle>
        ))}
      </TabsTitleWrapper>
      <SwipeContainer
        index={activeTab}
        onChangeIndex={(index: number) =>
          index < children.length && handleSetActiveTab(index)
        }
        disabled={isSwipeDisabled}
        threshold={20}
        resistance
        {...swipeHandlers}
      >
        {children}
      </SwipeContainer>
    </>
  );
};

export default memo(TabsView);
