import fluidRange from 'polished/lib/mixins/fluidRange';
import styled, { css } from 'styled-components';

export const globalStyles = `
  html, body, #root, #root>div:nth-child(1) {
    height: 100%
  }

  * {
    box-sizing: border-box;
  }

  body {
    position: relative;
    background-color: #fff;
    margin: 0;
    padding: 0;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    overflow-x: hidden;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", smonospace;
  }

  h1 {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    font-size: 26px;
  }

  a {
    outline: none;
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    &::-moz-focus-inner {
      border: 0;
    }
  }

  textarea,
  input,
  input[type="text"],
  input[type="password"],
  input[type="button"],
  input[type="submit"] {
    -webkit-appearance: none;
    border-radius: 0;
  }

  .ReactCollapse--collapse {
    transition: height 500ms;
  }

  .rc-slider-tooltip {
    z-index: 3;
  }

  .rc-slider-tooltip .rc-slider-tooltip-inner {
    background: none;
    box-shadow: none;
    font-size: 14px;
    color: #000;
  }

  #intercom-container .intercom-messenger-frame {
    left: 100px;
    max-height: 604px;

    @media (max-width: 768px) {
      top: auto;
      left: auto;
      right: 15px;
    }

    @media (max-width: 575px) {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
      border-radius: inherit;
    }
  }

  #intercom-container .intercom-borderless-frame {
    left: 10px;
    bottom: 10px;
  }

  .intercom-app > div:nth-child(2) {
    opacity: 0 !important;
  }
`;

export const breakpoints = {
  xs: '575',
  sm: '768',
  md: '990',
  lg: '1200',
  xl: '1440',
};

export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;

  return accumulator;
}, {});

export const colors = {
  white: '#fff',
  almostWhite: '#fcfcfc',
  almostWhiteDarker: '#fafafa',
  whiteSmoke: '#f2f2f2',
  black: '#000',
  black50: '#808080',
  black05: 'rgba(0, 0, 0, 0.05)',
  red: '#ff5d5f',
  darkGrey: '#9a9a9a',
  darkGrey05: 'rgba(154,154,154, 0.5)',
  grey: '#c8c9cc',
  greyInactive: '#b9babb',
  grey75: 'rgba(200, 201, 204, 0.75)',
  lightgrey: '#e1e2e6',
  lightgrey03: 'rgba(225, 226, 230, 0.3)',
  primary: '#3e82f7',
  primaryLight: '#2681ff',
  primaryPale: 'rgb(231, 239, 254)',
  secondary: '#1fd983',
  primary05: 'rgba(62, 130, 247, 0.5)',
  primary10: 'rgba(62, 130, 247, 0.1)',
  secondary05: 'rgba(31, 217, 131, 0.5)',
  red05: 'rgba(255, 93, 95, 0.5)',
  green: '#49A430',
  lightgreen: '#1fd983',
  yellow: '#fea33f',
  yellow05: 'rgba(254, 223, 124, 0.5)',
  fb: '#4460a0',
  vk: '#5181b8',
  mairu: '#168ee2',
  pending: '#ffaf5d',
  cancel: '#ff5d5f',
  success: '#1fd983',
  snow: '#fafafa',
};

export const range = (
  prop,
  fromSize,
  toSize,
  minScreen = `${breakpoints.sm}px`,
  maxScreen = `${breakpoints.xl}px`,
) =>
  fluidRange(
    {
      prop,
      fromSize,
      toSize,
    },
    minScreen,
    maxScreen,
  );

export const theme = {
  ...colors,
  ...media,
  range,
  breakpoints,
};

export const Hidden = styled.div`
  ${({ size }) => media[size]`
    display: none;
  `}
`;

export const HiddenInline = styled.div`
  display: inline-block;

  ${({ size }) => media[size]`
    display: none;
  `}
`;

export const Visible = styled.div`
  display: none;

  ${({ size }) => media[size]`
    display: flex;
    margin: auto;
  `}
`;

export const VisibleInline = styled.div`
  display: none;

  ${({ size }) => media[size]`
    display: inline-block;
  `}
`;

export const visibleOnlyForSize = (size) => visibleOnlyForSizes([size]);
export const hiddenOnlyForSize = (size) => hiddenOnlyForSizes([size]);

export const visibleOnlyForSizes = (sizes) => {
  return Object.keys(breakpoints)
    .filter((s) => sizes.includes(s) === false)
    .map((size) => `hidden-${size}`)
    .join(' ');
};

export const hiddenOnlyForSizes = (sizes) => {
  return Object.keys(breakpoints)
    .filter((s) => sizes.includes(s) === true)
    .map((size) => `hidden-${size}`)
    .join(' ');
};
