import { useEffect } from "react";

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import CartAPI from "@/api/cart_api";
import ItemsAPI from "@/api/items_api";
import StorageAPI from "@/api/storage_api";

import { isUnprocessedRequest } from "@/containers/Storage/components/ServiceRequests/helpers";

import formatter from "@/utils/formatter";
import { toastResponseError } from "@/utils/responseMessageHelper";

import useOutgoingStore from "@/store/useOutgoingStore";
import { RightPanelType } from "@/store/useRightPanelStore";
import { requestNames } from "@/store/useServicesRequestStore";
import {
  defaultStorageParams,
  selectParcel,
  StorageItem,
} from "@/store/useStorageStore";
import { useCartStore, useRightPanelStore, useStorageStore } from "@/store";
import { InventoryItemResponseDto } from "@/types/api/items";
import {
  AddParcelsToCartPayload,
  ShipmentResponseDto,
  ShopsResponseDto,
  StorageParamsDto,
  StorageResponseDto,
} from "@/types/api/shipments";
import { StorageQueryKey as QueryKey } from "@/types";

export const transformStorageItems = (
  items: StorageResponseDto[],
): StorageItem[] =>
  items.map((item) => {
    const serviceRequestCount: { [key: string]: string } = {};

    item.service_requests_count.forEach((request) => {
      const requestNameKey = request[0].toLowerCase();
      const requestName =
        requestNames[requestNameKey as keyof typeof requestNames];
      if (requestName) {
        serviceRequestCount[requestName] = request[1];
      }
    });

    const { service_requests_count, ...rest } = item;

    return { ...rest, serviceRequestCount };
  });

export const useFetchStorage = (
  params: StorageParamsDto | null,
  options?: Omit<
    UseInfiniteQueryOptions<AxiosResponse<StorageResponseDto[]>>,
    "queryKey" | "queryFn"
  >,
) => {
  const paramsDto = params ? params : defaultStorageParams;
  const {
    updateIsLoadingStorage,
    updateStorageParams,
    updateIsHasStorageItems,
    updateStorageTotalWeight,
    updateStorageTotalAmount,
    updateStorageItems,
    totalStorageAmount,
    isHasStorageItems,
    isAddParcelsToCart,
    selectedStorage,
    updateSelectedStorage,
    updateStorageTotalPage,
  } = useStorageStore();

  const fetchStorage = useInfiniteQuery({
    queryKey: [QueryKey.Storage],
    queryFn: ({ pageParam = 1 }) =>
      StorageAPI.getStorage({ page: pageParam, filter: paramsDto.filter }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const totalPages = Math.ceil(
        lastPage.headers["total"] / lastPage.headers["per-page"],
      );
      const nextPage = allPages.length + 1;
      return nextPage <= totalPages ? nextPage : undefined;
    },
  });

  const {
    data,
    isSuccess,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = fetchStorage;

  useEffect(() => {
    if (error) {
      toastResponseError(error);
    }
  }, [error]);

  useEffect(() => {
    updateIsLoadingStorage(isLoading || isFetchingNextPage);
    if (isLoading || isFetchingNextPage) {
      const isAllFetch = totalStorageAmount === paramsDto.filter.per_page;
      const shouldDropSelected = isAddParcelsToCart ? false : !isAllFetch;

      const selected = shouldDropSelected ? [] : selectedStorage;
      updateSelectedStorage(selected);
    }
  }, [isLoading, isFetchingNextPage]);

  useEffect(() => {
    if (isSuccess && data) {
      const allData = data.pages.map((page) => page.data).flat();
      const lastPage = data.pages[data.pages.length - 1];
      const { headers } = lastPage;

      const totalAmountFromHeader = headers["total"] ?? 0;
      const totalWeightFromHeader = headers["total-weight"] ?? 0;
      const totalPage = Math.ceil(totalAmountFromHeader / headers["per-page"]);

      const totalAmount = headers
        ? parseInt(totalAmountFromHeader)
        : totalAmountFromHeader;
      const totalWeight = headers
        ? formatter.roundTo2Decimal(parseFloat(totalWeightFromHeader))
        : parseFloat(totalWeightFromHeader);

      const transformedItems = transformStorageItems(allData);

      const hasItems = isHasStorageItems || !!transformedItems.length;

      updateStorageItems(transformedItems);
      updateStorageTotalAmount(totalAmount);
      updateStorageTotalWeight(totalWeight);
      updateStorageTotalPage(totalPage);
      updateIsHasStorageItems(hasItems);
      updateStorageParams(paramsDto);
      updateIsLoadingStorage(false);
    }
  }, [isSuccess, data]);

  return {
    ...fetchStorage,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export const useInventoryItems = (
  paramsDto: StorageParamsDto,
  options?: Omit<
    UseQueryOptions<InventoryItemResponseDto[]>,
    "queryKey" | "queryFn"
  >,
) => {
  const {
    updateIsLoadingStorage,
    updateInventoryItemsSearch,
    updateIsLoadingInventoryItems,
  } = useStorageStore();

  const fetchInventoryItems = useQuery({
    queryKey: [QueryKey.StorageInventoryItems],
    queryFn: () => ItemsAPI.getInventoryItems(paramsDto),
    ...options,
  });

  const { isLoading, isSuccess, data, error } = fetchInventoryItems;

  useEffect(() => {
    updateIsLoadingInventoryItems(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (error) {
      toastResponseError(error);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess && data) {
      updateIsLoadingStorage(false);
      updateInventoryItemsSearch(data);
    }
  }, [isSuccess, data]);

  return fetchInventoryItems;
};

export const useShops = (
  options?: Omit<UseQueryOptions<ShopsResponseDto[]>, "queryKey" | "queryFn">,
) => {
  const { updateShops } = useStorageStore();

  const fetchShops = useQuery({
    queryKey: [QueryKey.StorageShops],
    queryFn: () => StorageAPI.getShops(),
    ...options,
  });

  const { data, isSuccess } = fetchShops;

  useEffect(() => {
    if (isSuccess && data) {
      updateShops(data);
    }
  }, [isSuccess, data]);

  return fetchShops;
};

export const transformDetailedShipment = (
  shipment: ShipmentResponseDto,
): ShipmentResponseDto => {
  const updatedServiceRequests = shipment.service_requests.map((request) => ({
    ...request,
    service_request_type: {
      ...request.service_request_type,
      type: request.service_request_type.type.toLowerCase(),
    },
  }));

  const additionalPhotosRequest = updatedServiceRequests.find(
    (request) => request.service_request_type.type === "additional photos",
  );

  const additionalPictures = additionalPhotosRequest
    ? additionalPhotosRequest.pictures
    : [];
  const mergedPictures = [...shipment.pictures, ...additionalPictures];

  return {
    ...shipment,
    service_requests: updatedServiceRequests,
    pictures: mergedPictures,
    items: shipment.items,
  };
};

export const useShipment = (
  id: number,
  options?: Omit<UseQueryOptions<ShipmentResponseDto>, "queryKey" | "queryFn">,
) => {
  const { updateDetailedShipment, updateIsLoadingShipment, detailedShipment } =
    useStorageStore();

  const fetchShipments = useQuery({
    queryKey: [QueryKey.StorageShipment, id],
    queryFn: () => StorageAPI.getShipment(id),
    ...options,
  });

  const { data, isSuccess, isLoading } = fetchShipments;

  useEffect(() => {
    if (isSuccess && data) {
      const transformedDetailedShipment = transformDetailedShipment(data);

      updateDetailedShipment({
        ...detailedShipment,
        [data.id]: transformedDetailedShipment,
      });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    updateIsLoadingShipment(isLoading);
  }, [isLoading]);

  return fetchShipments;
};

export const useStorageCreateCustomsDeclaration = () => {
  const queryClient = useQueryClient();
  const { updateDetailedShipment, detailedShipment } = useStorageStore();
  const { detailedShipment: outgoingDetailedShipment } = useOutgoingStore();

  const createExpectedItem = useMutation({
    mutationFn: StorageAPI.createCustomsDeclaration,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.StorageCreateCustomDeclaration],
      });
    },
    onSuccess: (customDeclarationDto, { itemId }) => {
      const newDeclarations = customDeclarationDto.data;
      const shipment = detailedShipment[itemId] ||
        outgoingDetailedShipment[itemId] || { items: [] };
      const currentItem = shipment.items[0] ?? { customs_declarations: [] };

      const updatedItem = {
        ...shipment,
        items: [
          {
            ...currentItem,
            customs_declarations: [
              ...currentItem.customs_declarations,
              newDeclarations,
            ],
          },
        ],
      };

      updateDetailedShipment({
        ...detailedShipment,
        [itemId]: updatedItem,
      });
    },
  });

  return createExpectedItem;
};

export const useStorageUpdateCustomsDeclaration = () => {
  const queryClient = useQueryClient();
  const { updateDetailedShipment, detailedShipment } = useStorageStore();
  const { detailedShipment: outgoingDetailedShipment } = useOutgoingStore();

  const updateCustomsDeclaration = useMutation({
    mutationFn: StorageAPI.updateCustomsDeclaration,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.StorageUpdateCustomDeclaration],
      });
    },
    onSuccess: (customDeclarationDto, { itemId, id }) => {
      const updDeclaration = customDeclarationDto.data;
      const shipment = detailedShipment[itemId] ||
        outgoingDetailedShipment[itemId] || { items: [] };
      const currentItem = shipment.items[0] ?? { customs_declarations: [] };

      const updatedItem = {
        ...shipment,
        items: [
          {
            ...currentItem,
            customs_declarations: currentItem.customs_declarations.map(
              (item) => (item.id === id ? updDeclaration : item),
            ),
          },
        ],
      };

      updateDetailedShipment({
        ...detailedShipment,
        [itemId]: updatedItem,
      });
    },
  });

  return updateCustomsDeclaration;
};

export const useStorageDeleteCustomsDeclaration = () => {
  const queryClient = useQueryClient();
  const { updateDetailedShipment, detailedShipment } = useStorageStore();
  const { detailedShipment: outgoingDetailedShipment } = useOutgoingStore();

  const deleteCustomsDeclaration = useMutation({
    mutationFn: StorageAPI.deleteCustomsDeclaration,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.StorageDeleteCustomDeclaration],
      });
    },
    onSuccess: (_, { itemId, id }) => {
      const shipment = detailedShipment[itemId] ||
        outgoingDetailedShipment[itemId] || { items: [] };

      const updatedItems = shipment.items.map((item) => ({
        ...item,
        customs_declarations: item.customs_declarations.filter(
          (declaration) => declaration.id !== id,
        ),
      }));

      updateDetailedShipment({
        ...detailedShipment,
        [itemId]: { ...shipment, items: updatedItems },
      });
    },
  });

  return deleteCustomsDeclaration;
};

export const useStorageImportCustomsDeclarations = () => {
  const queryClient = useQueryClient();

  const { updateIsLoadingShipment } = useStorageStore();

  const importCustomInformation = useMutation({
    mutationFn: StorageAPI.importCustomsDeclaration,
    onMutate: () => {
      updateIsLoadingShipment(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.StorageImportCustomDeclarations],
      });
    },
    onSuccess: () => {
      updateIsLoadingShipment(false);
    },
    onError: () => {
      updateIsLoadingShipment(false);
    },
  });

  return importCustomInformation;
};

export const useStorageUpdateAddons = () => {
  const queryClient = useQueryClient();

  const {
    updateIsLoadingShipment,
    updateStorageItems,
    storageItems,
    updateDetailedShipment,
    detailedShipment,
  } = useStorageStore();
  const { detailedShipment: outgoingDetailedShipment } = useOutgoingStore();

  const updateAddons = useMutation({
    mutationFn: StorageAPI.updateAddons,
    onMutate: () => {
      updateIsLoadingShipment(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.StorageUpdateAddons],
      });
    },
    onSuccess: (addonsDto, { id }) => {
      updateStorageItems(
        storageItems.map((item) =>
          item.id === id ? { ...item, addons: addonsDto.addons } : item,
        ),
      );
      const shipment =
        detailedShipment[id] || outgoingDetailedShipment[+id] || {};

      updateDetailedShipment({
        ...detailedShipment,
        [id]: {
          ...shipment,
          addons: addonsDto.addons,
        },
      });
    },
    onError: () => updateIsLoadingShipment(false),
  });

  return updateAddons;
};

export const useStorageUpdateComment = () => {
  const queryClient = useQueryClient();

  const {
    updateIsLoadingShipment,
    updateStorageItems,
    storageItems,
    updateDetailedShipment,
    detailedShipment,
  } = useStorageStore();
  const { detailedShipment: outgoingDetailedShipment } = useOutgoingStore();

  const updateComment = useMutation({
    mutationFn: StorageAPI.updateComment,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.StorageUpdateComment],
      });
    },
    onSuccess: (updateCommentDto, { id }) => {
      const comment = updateCommentDto.customer_comment;

      updateStorageItems(
        storageItems.map((item) =>
          item.id === id ? { ...item, customer_comment: comment } : item,
        ),
      );

      const shipment =
        detailedShipment[id] || outgoingDetailedShipment[id] || {};

      updateDetailedShipment({
        ...detailedShipment,
        [updateCommentDto.id]: {
          ...shipment,
          customer_comment: comment,
        },
      });
    },
    onError: () => updateIsLoadingShipment(false),
  });

  return updateComment;
};

export const useStorageUpdateItemComment = () => {
  const queryClient = useQueryClient();

  const { updateIsLoadingShipment, updateDetailedShipment, detailedShipment } =
    useStorageStore();
  const { detailedShipment: outgoingDetailedShipment } = useOutgoingStore();

  const updateItemComment = useMutation({
    mutationFn: ItemsAPI.updateItemComment,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.StorageUpdateItemComment],
      });
    },
    onSuccess: (updateCommentDto, { id }) => {
      const commentIndex = updateCommentDto.data.items.findIndex(
        (item) => item.id === id,
      );
      const comment =
        updateCommentDto.data.items[commentIndex].customer_comment;

      const shipment =
        detailedShipment[id] || outgoingDetailedShipment[id] || {};

      updateDetailedShipment({
        ...detailedShipment,
        [id]: {
          ...shipment,
          items: detailedShipment[id].items.map((item) =>
            item.id === id ? { ...item, customer_comment: comment } : item,
          ),
        },
      });
    },
    onError: () => updateIsLoadingShipment(false),
  });

  return updateItemComment;
};

export const addParcelsToCart = async (payload: AddParcelsToCartPayload) => {
  const { id, useCartItemsApi, decant_ids, shipmentId } = payload;

  const cart = useCartStore.getState().getCartSelectForOutgoing();
  const openRightPanel = useRightPanelStore.getState().openRightPanel;
  const { updateIsLoadingCart, updateCart } = useCartStore.getState();
  const {
    updateIsLoadingStorage,
    updateSelectedStorage,
    updateIsLoadingShipment,
    updateDetailedShipment,
    updateIsAddParcelsToCart,
    detailedShipment,
  } = useStorageStore.getState();

  try {
    updateIsLoadingCart(true);
    const response = useCartItemsApi
      ? await CartAPI.addInventoryParcelItems({
          id,
          decant_ids,
        })
      : await CartAPI.addParcels({ id });

    if (useCartItemsApi && shipmentId) {
      updateIsLoadingShipment(true);
      const res = await StorageAPI.getShipment(shipmentId);
      const transformedDetailedShipment = transformDetailedShipment(res);

      updateDetailedShipment({
        ...detailedShipment,
        [res.id]: transformedDetailedShipment,
      });
      updateIsLoadingShipment(false);
    }

    if (!useCartItemsApi) {
      updateIsAddParcelsToCart(true);
    }

    openRightPanel(RightPanelType.NOT_FINISHED, {
      detailedItemID: cart.items[0]?.id,
      parcel: cart,
    });

    updateCart(response.data);
    updateIsLoadingCart(false);
    updateSelectedStorage([]);
  } catch (error) {
    if (error instanceof AxiosError)
      toastResponseError(error?.response?.data.error);

    updateIsLoadingStorage(false);
    updateIsLoadingCart(false);
  }
};

export const selectAllStorage = async () => {
  const {
    storageItems,
    totalStorageAmount,
    storageParams,
    updateStorageParams,
    updateIsLoadingStorage,
  } = useStorageStore.getState();

  const getSelectedItems = (items: StorageItem[]) =>
    items.reduce(
      (selectedItems: number[], item) =>
        !isUnprocessedRequest(Object.keys(item?.serviceRequestCount)) &&
        !item.prohibited
          ? [...selectedItems, item.id]
          : selectedItems,
      [],
    );

  if (totalStorageAmount === storageItems.length) {
    selectParcel(getSelectedItems(storageItems));
    return null;
  }

  try {
    const fetchParams = {
      page: 1,
      filter: { ...storageParams.filter, per_page: totalStorageAmount },
    };
    const response = await StorageAPI.getStorage(fetchParams);
    updateStorageParams(fetchParams);

    const selectIds = getSelectedItems(transformStorageItems(response.data));
    selectParcel(selectIds);
  } catch (error) {
    toastResponseError(error);
    updateIsLoadingStorage(false);
  }
};
