import React, { FC, memo } from "react";

import dayjs from "dayjs";

import { BookTransactionHistoryItem } from "@/types/api/books";

import {
  TableCol,
  TableRow,
  Wrapper,
} from "./BooksTransactionsHistoryItemRow.styles";

interface BooksTransactionsHistoryItemRowProps {
  item: BookTransactionHistoryItem;
  disabled: boolean;
}

const BooksTransactionsHistoryItemRow: FC<
  BooksTransactionsHistoryItemRowProps
> = ({ item, disabled }) => {
  return (
    <Wrapper disabled={disabled}>
      <TableRow align="center">
        <TableCol xs={12} sm={2} md={2}>
          {item.reference_type ?? "-"}
        </TableCol>
        <TableCol xs={12} sm={3} md={3}>
          {item.description ?? "-"}
        </TableCol>
        <TableCol xs={12} sm={3} md={3}>
          {dayjs(item.created_at).format("YYYY-MM-DD")}
        </TableCol>
        <TableCol xs={12} sm={2} md={2}>
          {item.amount}
        </TableCol>
        <TableCol xs={12} sm={2} md={2}>
          {item.transaction_type}
        </TableCol>
      </TableRow>
    </Wrapper>
  );
};

export default memo(BooksTransactionsHistoryItemRow);
