import { FC, memo } from "react";

import { LoadingBar } from "@/components/LoadingBar";

import { ListHeader } from "@/styles/parcels";

import { useBooksTransactionsHistory } from "@/hooks/react-query/books";

import { BooksTransactionsHistoryList } from "./components/BooksTransactionsHistoryList";
import { StorageHeading, StyledGrid, Wrapper } from "./styles";

const BooksTransactionsHistory: FC = () => {
  const { data: historyItems, isLoading: isLoadingHistoryItems } =
    useBooksTransactionsHistory();

  const isHistoryItems = !!historyItems?.length;

  return (
    <Wrapper>
      <StyledGrid fluid>
        <LoadingBar isLoading={isLoadingHistoryItems} />
        <StorageHeading>{isHistoryItems && <ListHeader />}</StorageHeading>

        {isHistoryItems && (
          <BooksTransactionsHistoryList
            historyItems={historyItems}
            disabled={isLoadingHistoryItems}
          />
        )}
      </StyledGrid>
    </Wrapper>
  );
};

export default memo(BooksTransactionsHistory);
