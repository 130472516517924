import React, { FC, ReactNode, Suspense, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

import { HeaderContainer } from "@/containers/HeaderContainer";
import RightPanel from "@/containers/RightPanel";
import { SignInModal } from "@/containers/SignIn/components";
import { FullscreenImage } from "@/components/ImageViewer2";
import { LeftMenu } from "@/components/LeftMenu";
import { navWidth } from "@/components/LeftMenu/LeftMenu.styles";
import { LoadingPage } from "@/components/LoadingPage";

import { useDocumentTitle } from "@/hooks";

import { useRightPanelStore } from "@/store";

import { Content, ContentWrapper, LayoutWrapper } from "./Layout.styles";

interface LayoutProps {
  children: ReactNode;
  withMenu: boolean;
  rightPanel?: boolean;
  withHeader?: boolean;
}

const Layout: FC<LayoutProps> = ({
  children,
  withMenu,
  withHeader = false,
  rightPanel = false,
}) => {
  const { pathname } = useLocation();
  const { activeTab, closeRightPanel, isOpenRightPanel } = useRightPanelStore();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isExpiredToken = pathname?.split("?")[1] === "signInModal";
  const { t } = useTranslation("common");
  const path = pathname?.split("/")[1];

  const title = t(`pages.${path || "basicInformation"}`);
  useDocumentTitle(title);

  const handleSetMenuOpen = useCallback(
    (isOpen: boolean) => {
      setIsMenuOpen(isOpen);
    },
    [setIsMenuOpen],
  );

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventDate) => {
      if (!isOpenRightPanel || activeTab !== 0) return;
      history.pushState(null, "", pathname);
      closeRightPanel();
      eventDate.event.stopPropagation();
      eventDate.event.preventDefault();
    },
    touchEventOptions: { passive: !isOpenRightPanel },
  });

  return (
    <LayoutWrapper {...swipeHandlers}>
      {rightPanel && <RightPanel />}

      <Suspense fallback={<LoadingPage />}>
        <LeftMenu
          isOpen={isMenuOpen}
          withMenu={withMenu}
          handleSetMenuOpen={handleSetMenuOpen}
        />
        {isExpiredToken && <SignInModal />}

        <ContentWrapper>
          {withHeader && (
            <HeaderContainer
              withMenu={withMenu}
              isMenuOpen={isMenuOpen}
              toggleMenu={toggleMenu}
            />
          )}
          <Content>{children}</Content>
          <FullscreenImage leftOffsetByTheme={navWidth} />
        </ContentWrapper>
      </Suspense>
    </LayoutWrapper>
  );
};

export default React.memo(Layout);
