import styled from "styled-components";

import { PageWrapper } from "@/layouts/Layout.styles";
import { Button } from "@/components/Button";

export const AccountWrapper = styled(PageWrapper)`
  width: 100%;
  position: relative;

  label {
    margin: 10px 0 0;
  }

  form > button {
    margin-top: 25px;
  }

  ${(props) => props.theme.xs`
    form {
      display: flex;
      flex-direction: column;

      button {
        align-self: center;
      }
    }
  `}
`;

export const Balance = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  background-color: rgba(225, 226, 230, 0.25);
  padding: 20px;
  font-size: 18px;
  font-weight: 700;
  margin: 15px 0 0 auto;
  color: ${(props) => props.theme.black50};

  ${(props) => props.theme.md`
    justify-content: center;
    order: -1;
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  `}
`;

export const BalanceText = styled.span`
  font-size: 24px;
  color: ${(props) => props.theme.black};
  margin-left: 4px;
`;

export const TransactionHistoryWrapper = styled.div`
  max-width: 255px;
  margin: 20px 0 10px auto;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.md`
    max-width: 100%;
  `}
`;

export const Transactions = styled.ul`
  list-style: none;
  margin: 10px 0;
  padding: 0 10px;
`;

export const ShowMoreButton = styled(Button)`
  margin: 0 auto;
  padding: 5px 10px;
`;
