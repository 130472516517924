export const getAddonDescription = (id, addons) => {
  const currentAddon = addons.find(
    (addon) => addon.id === id || addon.code === id,
  );

  return currentAddon ? currentAddon.description : null;
};

export const getRedirectPath = (link, location) => {
  const isNextStep =
    !location.state || location.pathname === location.state.prevPath;
  const redirectPath = isNextStep ? link : location.state.prevPath;

  return redirectPath;
};

export const getAllItemsAddons = (addons, items, values = []) => {
  const allItemsAddons = [];

  addons &&
    addons.forEach((addon) => {
      let isAllHave = true;

      items.forEach((item) => {
        const itemAddon = values[item.id] || item.addons;

        if (!(itemAddon && itemAddon.includes(addon.id))) isAllHave = false;
      });

      if (isAllHave) allItemsAddons.push(addon.id);
    });

  return allItemsAddons.length ? allItemsAddons : null;
};

export const getConsolidationAddonsIconsImage = (ids, addons, size = 18) => {
  return getAddonsIconsImage(ids, addons, size, (addon) => addon.code);
};

export const getAddonsIconsImage = (
  ids,
  addons,
  size = 18,
  propGetter = (addon) => addon.id,
) => {
  const iconUrls = [].concat(ids).map((id) => {
    const foundAddon = addons.find((addon) => id === propGetter(addon));
    return foundAddon ? foundAddon.icon_url : null;
  });

  return Array.isArray(iconUrls) ? (
    iconUrls.map((iconUrl) => (
      <img key={iconUrl} src={iconUrl} height={size} width={size} alt="" />
    ))
  ) : (
    <img src={iconUrls} alt="" />
  );
};

export const getAddonPrice = (id, addons) => {
  const currentAddon = addons.find(
    (addon) => addon.id === id || addon.code === id,
  );

  return currentAddon ? currentAddon.price : null;
};
