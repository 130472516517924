export enum IconType {
  Clients = "clients",
  Address = "address",
  Arrow = "arrow",
  CheckPackage = "checkPackage",
  CheckboxSelect = "checkbox-select",
  Close = "close",
  Comment = "comment",
  Coupon = "coupon",
  Copy = "copy",
  Custom = "custom",
  Dashboard = "dashboard",
  Delete = "delete",
  Delivery = "delivery",
  Exclamation = "exclamation",
  Faq = "faq",
  FileCSV = "fileCSV",
  Fullscreen = "fullscreen",
  Remove = "remove",
  Good = "good",
  Help = "help",
  Id = "id",
  Info = "info",
  LockClosed = "lock-closed",
  LockOpen = "lock-open",
  Money = "money",
  Orders = "orders",
  Package = "package",
  Parcel = "parcel",
  Price = "price",
  Process = "process",
  Add = "add",
  Return = "return",
  Timer = "timer",
  Triangle = "triangle",
  Wrong = "wrong",
  Vk = "vk",
  Fb = "fb",
  Mailru = "mailru",
  Search = "search",
  Edit = "edit",
  Menu = "menu",
  MenuBurger = "menuBurger",
  AdditionalPhotos = "additionalPhotos",
  PowerCheck = "powerCheck",
  ServiceByInstruction = "serviceByInstruction",
  NotSelectedDelivery = "not-selected-delivery",
  Pause = "pause",
  Filter = "filter",
  PackOptions = "pack-options",
  Fragile = "fragile",
  RemoveBrochure = "remove-brochure",
  RemoveShoeBox = "remove-shoe-box",
  SaveMailBox = "save-mail-box",
  SaveShoeBox = "save-shoe-box",
  ParcelInside = "parcel-inside",
  RightArrow = "right-arrow",
  Messenger = "messenger",
  MpsIcon = "mps-icon",
  Tools = "tools",
  RequestInventoryShipment = "requestInventoryShipment",
}
