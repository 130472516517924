import React, { FC, useCallback, useEffect } from "react";

import { isMobile } from "react-device-detect";
import { FocusOn } from "react-focus-on";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";

import { Icon } from "@/components/Icon";

import { breakpoints } from "@/styles";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";
import { IconType } from "@/enums";

import { isInIgnoredElement, PANEL_COMPONENTS } from "./helpers";
import { CloseButton, Container, Wrapper } from "./RightPanel.styles";

const RightPanel: FC = () => {
  const { t } = useTranslation("common");
  const {
    closeRightPanel,
    openRightPanel,
    isOpenRightPanel,
    panelType,
    panelData,
    activeTab,
  } = useRightPanelStore();
  const { pathname } = useLocation();
  const panelRef = React.useRef(null);
  const Panel = PANEL_COMPONENTS[panelType as keyof typeof PANEL_COMPONENTS];
  const isScrollDisabled =
    isOpenRightPanel && window.innerWidth <= +breakpoints.xs;

  const handleClose = useCallback(() => {
    if (!isOpenRightPanel) return;

    closeRightPanel();
  }, [closeRightPanel, isOpenRightPanel]);

  useOnClickOutside(panelRef, (event) => {
    if (pathname === "/parcels") return;
    if (pathname === "/parcels/expected") return;
    if (pathname === "/books/add-funds" && !isMobile) return;
    if (pathname.startsWith("/parcels/inventory")) return;

    // HOTFIX for popover https://github.com/Andarist/use-onclickoutside/issues/1
    const clickOnIgnoreElement = isInIgnoredElement(
      event.target,
      "ServiceTooltipItem",
    );

    isOpenRightPanel &&
      !clickOnIgnoreElement &&
      window.addEventListener("mouseup", handleClose, true);
  });

  const handleKeyDown: EventListener = (event: Event) => {
    if (event instanceof KeyboardEvent && event.key === "Escape") {
      handleClose();
    }
  };

  useEffect(() => {
    if (panelData && panelData.preventAutoCloseOnNavigationChange) {
      openRightPanel(RightPanelType.STORAGE_ITEM, {
        ...panelData,
        preventAutoCloseOnNavigationChange: false,
      });
      return;
    }
    handleClose();
  }, [pathname]);

  let startX: number | null = null;
  let startY: number | null = null;

  const handleTouchStart = (event: TouchEvent) => {
    const touch = event.touches[0];
    startX = touch.clientX;
    startY = touch.clientY;
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (startX === null || startY === null) {
      return;
    }

    const touch = event.touches[0];
    const diffX = touch.clientX - startX;
    const diffY = touch.clientY - startY;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      if (diffX > 0) {
        if (isOpenRightPanel && activeTab === 0) {
          event.preventDefault();
        }
      }
    }
  };

  const handleTouchEnd = () => {
    startX = null;
    startY = null;
  };

  useEffect(() => {
    if (isOpenRightPanel) {
      window.addEventListener("keydown", handleKeyDown, true);
      window.removeEventListener("mousedown", handleClose, true);
      window.addEventListener("touchstart", handleTouchStart, {
        passive: false,
      });
      window.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
      window.addEventListener("touchend", handleTouchEnd, { passive: false });
      window.addEventListener("popstate", handleClose, true);
    } else {
      window.removeEventListener("keydown", handleKeyDown, true);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("popstate", handleClose, true);
    }

    return () => {
      window.removeEventListener("mouseup", handleClose, true);
      window.removeEventListener("mousedown", handleClose, true);
      window.removeEventListener("popstate", handleClose, true);
    };
  }, [isOpenRightPanel, handleKeyDown, handleClose]);

  return (
    <FocusOn autoFocus enabled={isScrollDisabled}>
      <Wrapper ref={panelRef} $isOpen={isOpenRightPanel}>
        <Container>
          <CloseButton
            title={t("common.close")}
            aria-label="close-panel"
            onClick={handleClose}
          >
            <Icon type={IconType.Close} />
          </CloseButton>
          {!!Panel && (
            <Panel
              isOpen={isOpenRightPanel}
              key={panelData}
              panelData={panelData}
            />
          )}
        </Container>
      </Wrapper>
    </FocusOn>
  );
};

export default RightPanel;
