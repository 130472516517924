import React, { FC, useCallback } from "react";

import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";

import mpsIcon from "@/components/Icon/icons/mps_icon.gif";
import { PackageSizes } from "@/components/PackageSizes";
import { ServiceLogo } from "@/components/ServiceLogo";

import { ExcessMarker, OutgoingCol } from "@/styles/outgoing";
import { Currency, ParcelRow } from "@/styles/parcels";

import formatter from "@/utils/formatter";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";
import { OutgoingComponentCommonProps } from "@/types";

import { MPSIcon, Price, Weight } from "./WaitingFor.styles";

const WaitingForPayment: FC<OutgoingComponentCommonProps> = React.memo(
  ({ units, parcel }) => {
    const { t } = useTranslation("common");
    const { isOpenRightPanel, panelData, openRightPanel } =
      useRightPanelStore();

    const deliveryName =
      "preferred_carrier" in parcel ? parcel.preferred_carrier : "";
    const isSelectedShipment =
      isOpenRightPanel && panelData && panelData.detailedItemID === parcel.id;
    const packageCount =
      ("package_count" in parcel && parcel.package_count) || 0;
    const hasFewPackages = packageCount > 1;
    const hasExcessItems =
      "excess_items" in parcel && parcel.excess_items.length > 0;
    const depth = ("depth" in parcel && parcel.depth) || 0;
    const height = ("height" in parcel && parcel.height) || 0;
    const width = ("width" in parcel && parcel.width) || 0;
    const estimateTotal = ("estimate" in parcel && parcel.estimate.total) || 0;
    const weight =
      "total_weight" in parcel ? Number(parcel.total_weight).toFixed(2) : 0;

    const handleClick = useCallback(
      () =>
        openRightPanel(RightPanelType.WAITING_PAYMENT, {
          detailedItemID: parcel.id,
          excessItems: "excess_items" in parcel ? parcel.excess_items : [],
          returnPreviousPanel: RightPanelType.WAITING_PAYMENT,
        }),
      [openRightPanel, parcel],
    );

    const getPackageDimensions = () => {
      if (packageCount === 1) {
        return (
          <PackageSizes
            width={width}
            height={height}
            depth={depth}
            units={units}
          />
        );
      } else {
        const countId = packageCount <= 10 ? packageCount : "nth";
        return t(`conjugation.packages.${countId}`, { count: packageCount });
      }
    };

    return (
      <ParcelRow selected={isSelectedShipment} onClick={handleClick}>
        <OutgoingCol xs={3} sm={2} xl={1}>
          {parcel.sku}
          {hasExcessItems ? <ExcessMarker /> : null}
          <Visible xs>
            {hasFewPackages ? <MPSIcon icon={mpsIcon} size={[14, 14]} /> : null}
          </Visible>
        </OutgoingCol>
        <OutgoingCol xs={5} sm={3} xl={2}>
          <ServiceLogo serviceKey={deliveryName} />
          {deliveryName}
        </OutgoingCol>
        <Hidden xs>
          <OutgoingCol xs={0} sm={2} xl={1}>
            {`${formatter.weight(+weight)} ${t(`units.${units}.primaryShort`)}`}
          </OutgoingCol>
          <OutgoingCol xs={0} sm={3} xl={2}>
            {getPackageDimensions()}
          </OutgoingCol>
        </Hidden>
        <OutgoingCol xs={4} sm={2}>
          <Price>
            <Currency>$</Currency> {formatter.currency(estimateTotal)}
            <Visible xs>
              <Weight>{` / ${(+weight).toFixed(0)} ${t(
                `units.${units}.primaryShort`,
              )}`}</Weight>
            </Visible>
          </Price>
        </OutgoingCol>
      </ParcelRow>
    );
  },
);

export default WaitingForPayment;
