import styled from "styled-components";

import { ParcelCol, ParcelRow } from "@/styles/parcels";

interface WrapperProps {
  disabled: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  &:not(:first-child) > div {
    border-top: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const TableCol = styled(ParcelCol)`
  text-align: left;
  line-height: 20px;
  white-space: nowrap;
  user-select: none;

  .tooltip-content-checkbox {
    min-width: 18px;
  }

  &:nth-child(4) > div {
    margin-left: 15px;
  }

  ${(props) => props.theme.lg`
    white-space: normal;
  `}

  ${(props) => props.theme.md`
    white-space: nowrap;
  `}

  ${(props) => props.theme.xs`
    &:nth-child(4) > div {
      margin-left: 10px;
    }
  `}
  
  ${(props) => props.theme.xs`
    margin-bottom: 5px;
  `}
`;

export const TableRow = styled(ParcelRow)`
  ${({ theme }) => theme.range("padding", "0px", "6px")};

  ${(props) => props.theme.xs`
    padding: 5px 0 0;
  `};

  & > ${TableCol} {
    &:first-child {
      justify-content: ${({ theme }) =>
        theme.xs ? "center" : "space-between"};
    }

    &:last-child {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
  }
`;
