import React, { FC, MouseEvent, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { ServiceLogo } from "@/components/ServiceLogo";

import { ParcelButton, ParcelRow } from "@/styles/parcels";

import formatter from "@/utils/formatter";
import { useProgressDelivery } from "@/hooks";

import { CartSelectItem } from "@/store/useCartStore";
import { RightPanelType } from "@/store/useRightPanelStore";
import { useAuthStore, useCartStore, useRightPanelStore } from "@/store";
import { ButtonColor } from "@/enums";
import { OutgoingResponseDto } from "@/types/api/outgoing";

import { OutgoingCol, Step } from "./NotFinished.styles";

interface NotFinishedProps {
  units: string;
  parcel: OutgoingResponseDto | CartSelectItem;
  handleContinue: (event: MouseEvent) => void;
  handleCancel: (event: MouseEvent) => void;
}

const NotFinished: FC<NotFinishedProps> = React.memo(
  ({ parcel, units, handleContinue, handleCancel }) => {
    const { t } = useTranslation("common");
    const { isOpenRightPanel, panelData, openRightPanel } =
      useRightPanelStore();
    const { getAuthSelectFfid } = useAuthStore();
    const { cart } = useCartStore();

    const userFfid = getAuthSelectFfid();
    const [access] = useProgressDelivery(userFfid);
    const currentStep =
      access && Object.keys(access).filter((value) => value).length;
    const deliveryName = parcel.delivery ?? "";
    const hasDelivery = deliveryName || t("parcels.notSelectedYet");
    const weight = formatter.weight(+parcel.weight);
    const isSelectedShipment =
      isOpenRightPanel && panelData && panelData.detailedItemID === parcel.id;

    const clickParcel = useCallback(
      () =>
        openRightPanel(RightPanelType.NOT_FINISHED, {
          detailedItemID: cart?.id,
          parcel: cart,
        }),
      [openRightPanel, parcel, cart],
    );

    return (
      <ParcelRow selected={isSelectedShipment} onClick={clickParcel}>
        <OutgoingCol xs={3} sm={2} xl={1}>
          {parcel.sku}
        </OutgoingCol>
        <OutgoingCol xs={6} sm={3} xl={2}>
          <ServiceLogo serviceKey={deliveryName} />
          {hasDelivery}
        </OutgoingCol>
        <OutgoingCol xs={3} sm={2} md={1}>
          {`${weight} ${t(`units.${units}.primaryShort`)}`}
        </OutgoingCol>
        <OutgoingCol sm={1} className="hidden-xs">
          <Step title={t(`shipping.steps.${currentStep}`)}>{currentStep}</Step>
        </OutgoingCol>
        <OutgoingCol sm={4} md={5} xl={6} className="hidden-xs">
          <ParcelButton color={ButtonColor.Primary} onClick={handleContinue}>
            {t("common.continueWithProcessing")}
          </ParcelButton>
          <ParcelButton color={ButtonColor.Red} onClick={handleCancel}>
            {t("common.cancelAndDelete")}
          </ParcelButton>
        </OutgoingCol>
      </ParcelRow>
    );
  },
);

export default NotFinished;
