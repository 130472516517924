import styled from "styled-components";

import { Button } from "@/components/Button";

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-right: 10px;

  input {
    max-height: 34px;
    height: 34px;
    width: 100%;
    padding: 8px 30px 8px 10px;
  }

  ${(props) => props.theme.sm`
    margin: 0;
  `}
`;

export const SearchButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 34px;
  padding: 0 10px 0 5px;

  .icon {
    height: 15px;
    width: 15px;
    transition: background-color 0.3s;
  }

  &:hover .icon {
    background-color: ${(props) => props.theme.primary};
  }
`;
