import React, {
  ChangeEvent,
  FC,
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useFormik } from "formik";
import { isEqual } from "lodash";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button";
import { Checkbox } from "@/components/Checkbox";
import { LoadingBar } from "@/components/LoadingBar";
import { NoItemsPlaceholder } from "@/components/NoItemsPlaceholder";

import { ListHeader, ListHeaderTitle, StorageButton } from "@/styles/parcels";

import { useCart } from "@/hooks/react-query/cart";
import { useAddonsMethods } from "@/hooks/react-query/services";
import {
  addParcelsToCart,
  selectAllStorage,
  useFetchStorage,
  useInventoryItems,
  useShops,
} from "@/hooks/react-query/storage";
import { useProgressDelivery } from "@/hooks";

import { defaultStorageParams, selectParcel } from "@/store/useStorageStore";
import {
  useAuthStore,
  useCartStore,
  useServicesStore,
  useStorageStore,
} from "@/store";
import { ButtonColor, ButtonSize, ButtonVariant } from "@/enums";
import { StorageParamsDto } from "@/types/api/shipments";
import { StorageSearchValues } from "@/types/StorageForms/Search";

import { CartInfo } from "./components/CartInfo";
import { InventoryItems } from "./components/InventoryItems";
import { CompactSearch, Search } from "./components/Search";
import { searchFormik } from "./components/Search/helpers";
import { ResetFilterButton } from "./components/Search/Search.styles";
import { SearchPlaceholder } from "./components/SearchPlaceholder";
import { SelectedInfo } from "./components/SelectedInfo";
import { isUnprocessedRequest } from "./components/ServiceRequests/helpers";
import { StorageList } from "./components/StorageList";
import {
  Buttons,
  ButtonsWrapper,
  SelectAllSwitchWrapper,
  StorageHeading,
  StyledGrid,
  Wrapper,
} from "./Storage.styles";

const Storage: FC = () => {
  const {
    userAuth: {
      extended_tools: allowSearchByLocation,
      display_weight_in: units,
    },
    getAuthSelectFfid,
  } = useAuthStore();
  const {
    storageParams,
    shops,
    updateSelectedStorage,
    totalStorageAmount,
    totalStorageWeight,
    isLoadingStorage,
    selectedStorage: selectedParcels,
    isHasStorageItems,
    storageItems,
    isAddParcelsToCart,
    isLoadingInventoryItems,
    selectedStorageItemId,
  } = useStorageStore();
  const { isCartLoading, cart } = useCartStore();
  const { addonsMethods: addons } = useServicesStore();

  const userFfid = getAuthSelectFfid();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const [isStorageList, setIsStorageList] = useState(true);
  const [storageParamsDto, setStorageParamsDto] =
    useState<StorageParamsDto | null>(null);
  const [inventoryParamsDto, setInventoryParamsDto] =
    useState<StorageParamsDto | null>(null);
  const [handleResetCompactFunction, setHandleResetCompactFunction] =
    useState<Function>(() => null);
  const [, setAccess] = useProgressDelivery(userFfid);

  useCart({ enabled: !isCartLoading });
  const { fetchNextPage, refetch: refetchStorage } =
    useFetchStorage(storageParamsDto);
  const { refetch: getInventoryItems } = useInventoryItems(
    inventoryParamsDto ?? defaultStorageParams,
    {
      enabled: false,
    },
  );
  useAddonsMethods({ enabled: !addons.length });
  useShops({ enabled: !shops.length });

  useEffect(() => {
    if (!selectedStorageItemId) return;

    const targetElement = document.getElementById(
      selectedStorageItemId?.toString(),
    );
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selectedStorageItemId]);

  useEffect(() => {
    setStorageParamsDto({
      page: 1,
      per_page: totalStorageAmount,
      filter: defaultStorageParams.filter,
    });
  }, [isAddParcelsToCart]);

  const hasItems = !!storageItems.length;
  const hasSelected = hasItems && !!selectedParcels.length;
  const activeItems = useMemo(
    () =>
      storageItems.filter((item) => {
        const requests = item.serviceRequestCount;
        const isProhibited = item.prohibited;

        if (isProhibited && isProhibited === true) return false;
        if (!requests.length) return true;
        return !Object.keys(requests).find(
          (request: string) => request === "unprocessed",
        );
      }),
    [storageItems],
  );
  const isAllSelected =
    !isLoadingStorage &&
    hasSelected &&
    activeItems.length === selectedParcels.length;

  const onPagination = useCallback(() => {
    if (!isLoadingStorage) {
      setStorageParamsDto({
        ...storageParams,
        page: storageParams?.page + 1,
      });
      fetchNextPage();
    }
  }, [isLoadingStorage, storageParams]);

  const selectedRangeParcels = useCallback(
    (endParcelId: number) => {
      const getIndexParcel = (parcelId: number) =>
        storageItems.findIndex((item) => item.id === +parcelId);
      const startSelected = getIndexParcel(selectedParcels[0]);
      const endSelected = getIndexParcel(endParcelId);
      const from = Math.min(startSelected, endSelected);
      const to = Math.max(startSelected, endSelected);
      let selectedItems = [];

      for (let index = from; index <= to; index++) {
        const serviceRequestCount = storageItems[index].serviceRequestCount;
        const isProhibited = storageItems[index].prohibited;
        if (
          !isUnprocessedRequest(Object.keys(serviceRequestCount)) &&
          isProhibited !== true
        ) {
          selectedItems.push(storageItems[index].id);
        }
      }

      return selectedItems;
    },
    [selectedParcels, storageItems],
  );

  const isCartEmpty = !(cart?.items ?? []).length;

  const handleSelectAll = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.persist();
      selectAllStorage();
    },
    [selectAllStorage],
  );

  const handleDeselectAll = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.persist();
      updateSelectedStorage([]);
    },
    [updateSelectedStorage],
  );

  const handleToggleSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      if (isAllSelected === true) {
        updateSelectedStorage([]);
      } else {
        selectAllStorage();
      }
    },
    [selectAllStorage, updateSelectedStorage, isAllSelected],
  );

  const handleSend = useCallback(() => {
    if (!hasSelected && !isCartEmpty) {
      navigate("/shipping");
      return;
    }

    addParcelsToCart({ id: selectedParcels });
    if (isCartEmpty) {
      setAccess("initialSteps");
    }
  }, [selectedParcels, hasSelected, isCartEmpty]);

  const noItems = !isLoadingStorage && !isHasStorageItems;
  const noItemSearch = !isLoadingStorage && isHasStorageItems && !hasItems;

  const handleGetStorageSubmit = (values: StorageParamsDto) => {
    setIsStorageList(true);
    setStorageParamsDto(values);
  };

  const handleGetInventoryItemsSubmit = (values: StorageParamsDto) => {
    setIsStorageList(false);
    setInventoryParamsDto(values);
    getInventoryItems();
  };

  const formOptions = useFormik<StorageSearchValues>(
    searchFormik(
      handleGetStorageSubmit,
      handleGetInventoryItemsSubmit,
      refetchStorage,
    ),
  );

  const { values, initialValues, handleSubmit, handleReset } = formOptions;
  const isSearchDirty = !isLoadingStorage && !isEqual(values, initialValues);

  const handleResetClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleReset(e);
      handleSubmit();
    },
    [handleReset, handleSubmit],
  );

  const handleResetCompactClick = () => {
    if (!handleResetCompactFunction) return;
    handleResetCompactFunction();
  };

  const handleSetResetCompactFunction = (func: Function) =>
    setHandleResetCompactFunction(func);

  return (
    <Wrapper>
      <StyledGrid fluid>
        <LoadingBar
          isLoading={isLoadingStorage || isLoadingInventoryItems}
          isCartLoading={isCartLoading}
        />
        <StorageHeading>
          {hasItems && isStorageList && (
            <ListHeader>
              <Visible xs>
                <SelectAllSwitchWrapper>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={handleToggleSelectAll}
                  />
                </SelectAllSwitchWrapper>
              </Visible>
              <ListHeaderTitle>
                {t("parcels.total", { amount: totalStorageAmount })} /{" "}
                {`${totalStorageWeight.toFixed(2)} ${t(`units.${units}.primaryShort`)}`}
              </ListHeaderTitle>
              <Hidden xs>
                {isAllSelected ? (
                  <StorageButton onClick={handleDeselectAll}>
                    {t("common.deselect")}
                  </StorageButton>
                ) : (
                  <StorageButton onClick={handleSelectAll}>
                    {t("common.selectAll")}
                  </StorageButton>
                )}
                {isSearchDirty && (
                  <ResetFilterButton
                    color={ButtonColor.Red}
                    onClick={handleResetClick}
                  >
                    {t("parcels.search.resetFilter")}
                  </ResetFilterButton>
                )}
              </Hidden>
              <Visible xs>
                {isSearchDirty && (
                  <ResetFilterButton
                    color={ButtonColor.Red}
                    onClick={handleResetCompactClick}
                  >
                    {t("parcels.search.resetFilter")}
                  </ResetFilterButton>
                )}
              </Visible>
            </ListHeader>
          )}
          {(isHasStorageItems || noItems) && (
            <>
              <Hidden xs>
                <Search
                  isAllowSearchByLocation={allowSearchByLocation}
                  formOptions={formOptions}
                />
              </Hidden>

              <Visible xs>
                <CompactSearch
                  formOptions={formOptions}
                  handleSetResetCompactFunction={handleSetResetCompactFunction}
                />
              </Visible>
            </>
          )}
        </StorageHeading>

        {isHasStorageItems && isStorageList && (
          <StorageList
            addons={addons}
            units={units}
            parcels={storageItems}
            totalAmount={totalStorageAmount}
            selectedParcels={selectedParcels}
            handleSelect={selectParcel}
            onPagination={onPagination}
            selectedRangeParcels={selectedRangeParcels}
            disabled={isCartLoading}
          />
        )}
        {!isStorageList && <InventoryItems />}
        {isStorageList && (hasSelected || !isCartEmpty) && (
          <ButtonsWrapper>
            <Buttons>
              {hasSelected ? <SelectedInfo /> : <CartInfo />}
              <Button
                variant={ButtonVariant.Filled}
                color={
                  hasSelected ? ButtonColor.Primary : ButtonColor.Secondary
                }
                size={ButtonSize.Large}
                disabled={isCartLoading}
                onClick={handleSend}
              >
                {hasSelected ? t("common.addToCart") : t("common.sendTheCart")}
              </Button>
              {hasSelected && (
                <StorageButton
                  disabled={isCartLoading}
                  onClick={handleDeselectAll}
                >
                  {t("common.deselect")}
                </StorageButton>
              )}
            </Buttons>
          </ButtonsWrapper>
        )}
        {isStorageList && noItems && <NoItemsPlaceholder />}
        {isStorageList && (
          <>
            <Hidden xs>
              {noItemSearch && (
                <SearchPlaceholder handleReset={handleResetClick} />
              )}
            </Hidden>
            <Visible xs>
              {noItemSearch && (
                <SearchPlaceholder handleReset={handleResetCompactClick} />
              )}
            </Visible>
          </>
        )}
      </StyledGrid>
    </Wrapper>
  );
};

export default memo(Storage);
