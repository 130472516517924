import {
  AddonsMethodsResponseDto,
  ConsolidationAddonsMethodsResponseDto,
  DeliveryShippingMethodsResponseDto,
  PackingMethodsResponseDto,
} from "types/api/cart";

import axiosInstance from "./axios_instance";

const ServicesAPI = {
  getAllDeliveryMethods: async (): Promise<
    DeliveryShippingMethodsResponseDto[]
  > => {
    const res = await axiosInstance.get("/shipping_methods");
    return res.data;
  },
  getDeliveryMethods: async (): Promise<
    DeliveryShippingMethodsResponseDto[]
  > => {
    const res = await axiosInstance.get("/current_parcel/shipping_methods");
    return res.data;
  },
  getPackingMethods: async (): Promise<PackingMethodsResponseDto[]> => {
    const res = await axiosInstance.get("/current_parcel/optional_line_items");
    return res.data;
  },
  getAddonsMethods: async (): Promise<AddonsMethodsResponseDto[]> => {
    const res = await axiosInstance.get("/addons");
    return res.data;
  },
  getConsolidationAddonsMethods: async (): Promise<
    ConsolidationAddonsMethodsResponseDto[]
  > => {
    const res = await axiosInstance.get("/consolidation_addons");
    return res.data;
  },
};

export default ServicesAPI;
