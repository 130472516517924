import styled from "styled-components";

import { PreLoader } from "@/components/PreLoader";

import { ButtonColor, ButtonSize, ButtonVariant } from "@/enums";

import { variantStyles } from "./helpers";

interface CustomButtonProps {
  $variant: ButtonVariant;
  color: ButtonColor;
  size: ButtonSize;
}

export const CustomButton = styled.button<CustomButtonProps>`
  ${({ theme }) => theme.range("font-size", "13px", "14px")};
  cursor: pointer;
  padding: ${(props) =>
    props.size === ButtonSize.Large ? "12px 30px" : "9px 20px"};
  transition:
    box-shadow 0.2s,
    background-color 0.2s,
    color 0.2s,
    border 0.2s;

  ${({ color, $variant, theme }) => variantStyles[$variant](theme[color])};

  ${(props) => props.theme.xs`
    padding: 9px 20px;
  `}
`;

export const Spinner = styled(PreLoader)`
  margin-right: 5px;
  width: 20px;
  height: 14px;
  transform-origin: 0 -2px;
  transform: scale(0.3);
`;
