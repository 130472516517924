import React, { FC, MouseEvent, useCallback, useEffect } from "react";

import { isEmpty } from "lodash";
import queryString from "query-string";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Icon } from "@/components/Icon";
import { LoadingBar } from "@/components/LoadingBar";

import { HeaderTitle, ParcelButton } from "@/styles/parcels";

import formatter from "@/utils/formatter";
import {
  toastResponseError,
  toastResponseSuccess,
} from "@/utils/responseMessageHelper";
import { useDeleteCart } from "@/hooks/react-query/cart";
import { useOutgoing } from "@/hooks/react-query/outgoing";
import usePurchase from "@/hooks/react-query/purchase";
import { useAllDeliveryMethods } from "@/hooks/react-query/services";

import useOutgoingStore from "@/store/useOutgoingStore";
import { RightPanelType } from "@/store/useRightPanelStore";
import {
  useAuthStore,
  useCartStore,
  useRightPanelStore,
  useServicesStore,
} from "@/store";
import { ButtonColor, IconType } from "@/enums";

import { NoItemsPlaceholder } from "./components";
import { InProgress } from "./components/InProgress";
import { NotFinished } from "./components/NotFinished";
import { OutgoingSection } from "./components/OutgoingSection";
import { Paid } from "./components/Paid";
import {
  WaitingForInformation,
  WaitingForPayment,
} from "./components/WaitingFor";
import { OutgoingWrapper, PayButton } from "./Outgoing.styles";

const Outgoing: FC = React.memo(() => {
  const params = useParams();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();

  const { getCartSelectForOutgoing } = useCartStore();
  const {
    outgoingTypes: {
      paid: paidItemsData,
      packing_in_progress: packingItemsData,
      waiting_for_information: informationItemsData,
      waiting_for_payment: paymentItemsData,
    },
    getOutgoingSelectLoading,
  } = useOutgoingStore();
  const userCart = getCartSelectForOutgoing();
  const { paymentCompleteViaPayPal, paymentCompleteAllViaPaypal } =
    usePurchase();
  const { mutateAsync: paymentCompleteViaPayPalMutate } =
    paymentCompleteViaPayPal;

  const { mutateAsync: paymentCompleteAllViaPayPalMutate } =
    paymentCompleteAllViaPaypal;

  const items = paymentItemsData.items;
  const total = paymentItemsData?.total ?? items.length;
  const shouldFetchAll = total !== items.length;
  const loadMoreData = {
    type: paymentItemsData.type,
    params: {
      per_page: total,
      page: 1,
    },
  };

  const { refetch: getOutgoing } = useOutgoing(loadMoreData, {
    enabled: false,
  });

  const isLoading = getOutgoingSelectLoading();
  const hasItems = !!(paymentItemsData.items.length ||
    informationItemsData.items.length ||
    packingItemsData.items,
  length || userCart.items.length || paidItemsData.items.length);
  const consolidationId = location.state && location.state.consolidationId;
  const {
    userAuth: { display_weight_in: units },
  } = useAuthStore();
  const { openRightPanel } = useRightPanelStore();
  const { isCartLoading } = useCartStore();
  const { allDeliveryMethods } = useServicesStore();

  const { mutateAsync: deleteCart } = useDeleteCart();
  const { refetch: getAllDeliveryMethods } = useAllDeliveryMethods({
    enabled: false,
  });

  useEffect(() => {
    const query = queryString.parse(location.search);
    const parcelId = params?.id;
    const paymentResult = params?.payment_result;

    if (!parcelId || !paymentResult || isEmpty(query)) {
      return;
    }

    if (paymentResult === "success") {
      if (parcelId === "all") {
        paymentCompleteAllViaPayPalMutate({
          sign_id: query.sigh_id,
          PayerID: query.PayerID,
          token: query.token,
        });
      } else {
        paymentCompleteViaPayPalMutate({
          id: parcelId,
          data: { PayerID: query.PayerID, token: query.token },
        });
      }
      navigate("/parcels/outgoing", { replace: true });
    } else if (paymentResult === "cancel") {
      toastResponseError(t("error.paymentWasCanceled"));
      navigate("/parcels/outgoing", { replace: true });
    }
  }, []);

  useEffect(() => {
    !allDeliveryMethods.length && getAllDeliveryMethods();

    if (consolidationId) {
      const parcel =
        paymentItemsData.items.find((item) => item.id === consolidationId) ||
        informationItemsData.items.find(
          (item) => item.id === consolidationId,
        ) ||
        packingItemsData.items.find((item) => item.id === consolidationId) ||
        userCart.items.find((item) => item.id === consolidationId) ||
        null;
      const parcelState = parcel?.state;

      const namePanel =
        (parcelState === "packed" && RightPanelType.WAITING_PAYMENT) ||
        (parcelState === "requesting" && RightPanelType.NOT_FINISHED) ||
        RightPanelType.IN_PROGRESS;

      openRightPanel(namePanel, {
        detailedItemID: consolidationId,
        returnPreviousPanel: namePanel,
        foundPackageId: location.state.shipmentId,
        shipments: userCart.items[0]?.items,
        sku: userCart.items[0]?.sku,
      });
      navigate(location.pathname, { state: undefined, replace: true });
    }
  }, []);

  const clickPayAll = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (shouldFetchAll) getOutgoing();

      openRightPanel(RightPanelType.PAY_ALL);
    },
    [paymentItemsData],
  );

  const inProgressItemsCount = packingItemsData.total || "0";
  const informationItemsCount = informationItemsData.total || "0";
  const paymentItemsCount = paymentItemsData.total || "0";
  const paidItemsCount = paidItemsData.total || "0";

  const inProgressHeaderTitle = `${t(
    "parcels.inProgress",
  )}: ${inProgressItemsCount}`;
  const waitingForInformationHeaderTitle = `${t(
    "parcels.waitingForInformation",
  )}: ${informationItemsCount}`;

  const waitingForPaymentHeaderTitle = `${t(
    "parcels.waitingForPayment",
  )}: ${paymentItemsCount}`;

  const paidItemsHeaderTitle = `${t("parcels.paymentPaid")}: ${paidItemsCount}`;

  const onResumeOutgoingHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      navigate("/shipping");
    },
    [navigate],
  );

  const onCancelOutgoingHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (
        window.confirm(t("popup.notFinished.deletionConfirmation")) === true
      ) {
        deleteCart()
          .then(() => toastResponseSuccess(t("common.deleteCartSuccess")))
          .catch(toastResponseError);
      }
    },
    [deleteCart, t],
  );

  const renderPayAllButton = () => {
    return (
      <PayButton color={ButtonColor.Secondary} onClick={clickPayAll}>
        {`${t("shipping.total")}`}
        <b>{` $${formatter.currency(paymentItemsData.totalPrice ?? 0)}`}</b>
      </PayButton>
    );
  };

  return (
    <OutgoingWrapper>
      <Container fluid>
        <LoadingBar isLoading={isLoading} isCartLoading={isCartLoading} />
        <OutgoingSection
          isCollapseOpen
          data={userCart}
          isFetchCartData
          itemRenderer={(parcel) => (
            <NotFinished
              units={units}
              parcel={parcel}
              key={parcel.id}
              handleContinue={onResumeOutgoingHandler}
              handleCancel={onCancelOutgoingHandler}
            />
          )}
        >
          <Row>
            <Col xs={6}>
              <HeaderTitle>
                <Icon type={IconType.Pause} color="#ffaf5d" />
                {t("parcels.notFinished")}
              </HeaderTitle>
            </Col>
            <Col xs={6}>
              <Visible xs>
                <Row justify="center">
                  <Col xs={12}>
                    <ParcelButton
                      color={ButtonColor.Primary}
                      onClick={onResumeOutgoingHandler}
                    >
                      {t("common.resume")}
                    </ParcelButton>
                    <ParcelButton
                      color={ButtonColor.Red}
                      onClick={onCancelOutgoingHandler}
                    >
                      {t("common.cancel")}
                    </ParcelButton>
                  </Col>
                </Row>
              </Visible>
            </Col>
          </Row>
        </OutgoingSection>
        <OutgoingSection
          data={packingItemsData}
          countItemsPerSection={5}
          isCollapseOpen
          itemRenderer={(parcel) => (
            <InProgress units={units} parcel={parcel} key={parcel.id} />
          )}
        >
          <HeaderTitle>
            <Icon type={IconType.Add} color="#3ebcf7" />
            {inProgressHeaderTitle}
          </HeaderTitle>
        </OutgoingSection>
        <OutgoingSection
          data={informationItemsData}
          isCollapseOpen
          itemRenderer={(parcel) => (
            <WaitingForInformation
              units={units}
              parcel={parcel}
              key={parcel.id}
            />
          )}
        >
          <HeaderTitle>
            <Icon type={IconType.Exclamation} color="#d788ee" />
            {waitingForInformationHeaderTitle}
          </HeaderTitle>
        </OutgoingSection>
        <OutgoingSection
          data={paymentItemsData}
          isCollapseOpen
          itemRenderer={(parcel) => (
            <WaitingForPayment units={units} parcel={parcel} key={parcel.id} />
          )}
        >
          <Row>
            <Col xs={8} sm={12}>
              <HeaderTitle>
                <Icon type={IconType.Money} color="#1fd983" />
                {waitingForPaymentHeaderTitle}
              </HeaderTitle>
              <Hidden xs>{renderPayAllButton()}</Hidden>
            </Col>
            <Visible xs>
              <Col xs={4}>{renderPayAllButton()}</Col>
            </Visible>
          </Row>
        </OutgoingSection>
        <OutgoingSection
          data={paidItemsData}
          isCollapseOpen
          itemRenderer={(parcel) => (
            <Paid units={units} parcel={parcel} key={parcel.id} />
          )}
        >
          <HeaderTitle>
            <Icon type={IconType.Money} color="#D25825" />
            {paidItemsHeaderTitle}
          </HeaderTitle>
        </OutgoingSection>
        {!isLoading && !isCartLoading && !hasItems && <NoItemsPlaceholder />}
      </Container>
    </OutgoingWrapper>
  );
});

export default Outgoing;
