import React, { FC, MouseEvent, useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import { isEqual } from "lodash";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";

import { LoadingBar } from "@/components/LoadingBar";
import { NoItemsPlaceholder } from "@/components/NoItemsPlaceholder";

import { ListHeader } from "@/styles/parcels";

import {
  useExpectedItems,
  useExpectedShops,
} from "@/hooks/react-query/expected";
import { useAddonsMethods } from "@/hooks/react-query/services";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useRightPanelStore, useServicesStore, useStorageStore } from "@/store";
import { ButtonColor } from "@/enums";
import { ExpectedShipmentsDto } from "@/types/api/expected";
import { ExpectedSearchValues } from "@/types/ExpectedForms/Search";

import { ExpectedList } from "./components/ExpectedList";
import { CompactSearch, Search } from "./components/Search";
import { searchFormik } from "./components/Search/helpers";
import { ResetFilterButton } from "./components/Search/Search.styles";
import { SearchPlaceholder } from "./components/SearchPlaceholder";
import { StorageHeading, StyledGrid, Wrapper } from "./Expected.styles";

const Expected: FC = React.memo(() => {
  const { t } = useTranslation("common");
  const { addonsMethods: addons } = useServicesStore();
  const { openRightPanel } = useRightPanelStore();
  const {
    storageParams,
    expectedShops,
    expectedItemsSearch: expectedItems,
    totalExpectedAmount,
  } = useStorageStore();

  useAddonsMethods({ enabled: !addons.length });

  const [handleResetCompactFunction, setHandleResetCompactFunction] =
    useState<Function>(() => null);
  const hasItems = !!expectedItems.length;

  const { isLoading: isLoadingExpectedShops } = useExpectedShops({
    enabled: !expectedShops.length,
  });

  const [expectedParamsDto, setExpectedParamsDto] =
    useState<ExpectedShipmentsDto | null>(null);

  const { isLoading } = useExpectedItems(expectedParamsDto);

  const showExpectedParcelForm = () => {
    openRightPanel(RightPanelType.EXPECTED_ITEM);
  };

  useEffect(() => {
    showExpectedParcelForm();
  }, []);

  const onPagination = useCallback(
    () =>
      !isLoading &&
      setExpectedParamsDto({
        ...storageParams,
        page: storageParams.page + 1,
      }),
    [isLoading, storageParams],
  );

  const expectedHasItems = expectedItems && expectedItems.length > 0;

  const noItems = !isLoading && !expectedHasItems;
  const noItemSearch = !isLoading && expectedHasItems && !hasItems;

  const handleGetExpectedSubmit = (values: ExpectedShipmentsDto | null) => {
    setExpectedParamsDto(values);
  };

  const formOptions = useFormik<ExpectedSearchValues>(
    searchFormik(handleGetExpectedSubmit),
  );

  const { values, initialValues, handleReset, handleSubmit } = formOptions;

  const isSearchDirty = !isLoading && !isEqual(values, initialValues);

  const handleResetClick = useCallback(
    (event: MouseEvent) => {
      handleReset(event);
      handleSubmit();
    },
    [handleReset, handleSubmit],
  );

  const handleResetClickCompactSearch = () => {
    handleResetCompactFunction();
  };

  const handleSetResetCompactFunction = (func: Function) =>
    setHandleResetCompactFunction(func);

  return (
    <Wrapper>
      <StyledGrid fluid>
        <LoadingBar isLoading={isLoading || isLoadingExpectedShops} />
        <StorageHeading>
          {hasItems && (
            <ListHeader>
              <Hidden xs>
                {isSearchDirty && (
                  <ResetFilterButton
                    color={ButtonColor.Red}
                    onClick={handleResetClick}
                  >
                    {t("parcels.search.resetFilter")}
                  </ResetFilterButton>
                )}
              </Hidden>
              <Visible xs>
                {isSearchDirty && (
                  <ResetFilterButton
                    color={ButtonColor.Red}
                    onClick={handleResetClickCompactSearch}
                  >
                    {t("parcels.search.resetFilter")}
                  </ResetFilterButton>
                )}
              </Visible>
            </ListHeader>
          )}
          {(expectedHasItems || noItems) && (
            <>
              <Hidden xs>
                <Search formOptions={formOptions} />
              </Hidden>
              <Visible xs>
                <CompactSearch
                  formOptions={formOptions}
                  handleSetResetCompactFunction={handleSetResetCompactFunction}
                />
              </Visible>
            </>
          )}
        </StorageHeading>
        {expectedHasItems && (
          <ExpectedList
            parcels={expectedItems}
            totalAmount={totalExpectedAmount}
            onPagination={onPagination}
            disabled={isLoading}
            shops={expectedShops}
          />
        )}
        {noItems && <NoItemsPlaceholder />}
        <Hidden xs>
          {noItemSearch && <SearchPlaceholder handleReset={handleResetClick} />}
        </Hidden>
        <Visible xs>
          {noItemSearch && (
            <SearchPlaceholder handleReset={handleResetClickCompactSearch} />
          )}
        </Visible>
      </StyledGrid>
    </Wrapper>
  );
});

export default Expected;
