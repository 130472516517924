import { FC, memo, useId } from "react";

import { BookTransactionHistoryItem } from "@/types/api/books";

import { BooksRecipientListWrapper as BooksRecipientHistoryListWrapper } from "../BooksRecipientsList/BooksRecipientsList.styles";
import { BooksTransactionsHistoryItemRow } from "./BooksTransactionsHistoryItemRow";

interface BooksTransactionsHistoryListProps {
  historyItems: BookTransactionHistoryItem[];
  disabled: boolean;
}

const BooksTransactionsHistoryList: FC<BooksTransactionsHistoryListProps> = ({
  historyItems,
  disabled,
}) => {
  return (
    <BooksRecipientHistoryListWrapper>
      {historyItems.map((item) => {
        const id = useId();

        return (
          <BooksTransactionsHistoryItemRow
            item={item}
            key={id}
            disabled={disabled}
          />
        );
      })}
    </BooksRecipientHistoryListWrapper>
  );
};

export default memo(BooksTransactionsHistoryList);
