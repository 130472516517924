import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import BooksAPI from "@/api/books_api";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { BookTransactionHistoryItem } from "@/types/api/books";
import { BooksQueryKey as QueryKey } from "@/types";

type Params = { page: number };

export function useBooksTransactionsHistory(
  params: Params = { page: 1 },
): UseQueryResult<BookTransactionHistoryItem[], unknown> {
  return useQuery<BookTransactionHistoryItem[], unknown>({
    queryKey: [QueryKey.BooksTransactionsHistory, params],
    queryFn: () => BooksAPI.getBooksTransactionsHistory(params),
    onError: (e: unknown) => {
      toastResponseError(e);
    },
  } as UseQueryOptions<BookTransactionHistoryItem[], unknown>);
}

export function useRechargeBalance() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: BooksAPI.createRechargeBalance,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BooksCreateRechargeBalance],
      });
    },
  });
}
